
  import soquote, {
    LiItems,
    AddressItem,
    RepItem,
    ChangeDateItems,
    QtyItems,
  } from "@/types/soquote";
  import { defineComponent } from "vue";
  import { mapActions, mapGetters, mapState } from "vuex";
  import Search from "@/components/Search.vue";
  import InputText from "primevue/inputtext";
  import Dialog from "primevue/dialog";
  import Dropdown from "primevue/dropdown";
  import Accordion from "primevue/accordion";
  import AccordionTab from "primevue/accordiontab";
  import DataTable from "primevue/datatable";
  import Button from "primevue/button";
  import Column from "primevue/column";
  import ColumnGroup from "primevue/columngroup";
  import Row from "primevue/row";
  import InputNumber from "primevue/inputnumber";
  import Calendar from "primevue/calendar";
  import CustomerService from "@/services/CustomerService";
  import Customer, { basecontact } from "@/types/customer";
  import Contact from "@/types/contact";
  import ContactService from "@/services/ContactService";
  import useVuelidate from "@vuelidate/core";
  import { required, requiredIf, helpers } from "@vuelidate/validators";
  import PartsService from "@/services/PartsService";
  import SoQuoteService from "@/services/SOQuotesService";
  import ProspectService from "@/services/ProspectService";
  import SaleOppsService from "@/services/SaleOppsService";
  import SalesService from "@/services/SalesService";
  import Prospect from "@/types/prospect";
  import Part from "@/types/part";
  import { ResponseObject } from "@/types/response/response";
  import Response from "@/types/response/response";
  import SaleOpp from "@/types/saleOpps";
  import SalesOrder, { LisItems } from "@/types/salesorder";
  import ContactAutocomplete from "@/components/Autocompletes/Contact.vue";
  import Utils from "@/utility/utils";
  
  export default defineComponent({
    name: "SO Quote Dialog",
    props: {
      customer: Object,
      modelValue: String,
      show: Boolean,
      quote: Object,
      isEditing: Boolean,
      singleOption: Boolean,
      oldQuote: Object,
      id: String,
      newQuote: Boolean
    },
    emits: ["hide", "onSave"],
    components: {
      Search,
      InputText,
      Dialog,
      Dropdown,
      Accordion,
      AccordionTab,
      DataTable,
      Button,
      Column,
      ColumnGroup,
      Row,
      InputNumber,
      Calendar,
      ContactAutocomplete
    },
    setup() {
      return {
        v$: useVuelidate(),
      };
    },
    validations() {
      return {
        existingOpportunity: {
          required,
        },
        existingSOQuote: {
          required,
        },
        soQuote: {
          co_code: { required },
        },
        newSoQuoteCust: {
          required: helpers.withMessage("Please Select a Customer", 
          requiredIf(() => {
              return this.soQuote?.prospect == ""
            }),),
        },
        co_code: {
          required: helpers.withMessage(
            "Please Select a Company",
            requiredIf(() => {
              return this.soQuote?.co_code == "";
            })
          ),
        },
      };
    },
    created() {
      let currentUserDetails = this.getCurrentUserData;
      this.currentUserId = currentUserDetails.user_id;
      this.selectedQuoter = this.currentUserId.toUpperCase();
      this.initSoQuote();
      this.initControls();
      this.hidden = this.show;
      
      if (this.customer != null) {
        this.initCust();
      }
      if (this.getTerms == null) {
        this.fetchTerms();
      }
    },
    data() {
      return {
        hidden: true,
        soQuote: {} as soquote,
        salesOrder: {} as SalesOrder,
        part: {
          li: "",
          quantity: 0,
          number: "",
          date: "",
          disc_pct: "",
          wrap_desc: ""
        },
        cost_method: [
          {
            description: "Markup",
            value: "M",
          },
          {
            description: "Price File",
            value: "P",
          },
        ],
        newSoQuoteCust: "",
        newSoQuoteContact: "",
        prospectName: "",
        totalPrice: "",
        showPartModal: false,
        showAddExistingQuoteDialog: false,
        showAddOpportunityDialog: false,
        isLoadingOrder: false,
        isLoadingOpportunity: false,
        selectedQuote: "",
        existingSOQuote: "",
        existingOpportunity: "",
        saleOpp: {} as SaleOpp,
        isLoadingSOQuote: false,
        /* tracking flag to detect whether an opp is loaded in the dialog
        * reset every time the dialog is closed and or when an existing quote is
        * loaded in the dialog - check for usages.
        */
        isOppLoadedInDialog: false,
        soQuoteService: new SoQuoteService(process.env.VUE_APP_ABSTRACTION_API),
        saleOppService: new SaleOppsService(process.env.VUE_APP_ABSTRACTION_API),
        custService: new CustomerService(),
        partService: new PartsService(),
        prospectService: new ProspectService(process.env.VUE_APP_ABSTRACTION_API),
        salesService: new SalesService(),
        partLoading: false,
        submitLoading: false,
        isEditingPart: false,
        partNumber: "",
        selectedQuoter: "",
        currentUserId: "",
        currentDate: "",
        validThruDate: "",
        isFormSubmitted: false,
        showClear: true,
        editingRows: [],
        liItemObjectIndex: -1,
        qtyItemObjectIndex: -1,
        isLoadQuoteFormSubmitted: false,
        isLoadOppFormSubmitted: false,
        isLoadingQuote: false,
        isOpportunityEmpty: false,
        isOrderEmpty: false,
        statusMessage: "",
        disc_pct: "",
        oldQtyItems: [],
        status: [
          {
            description: "Closed",
            value: "C",
          },
          {
            description: "New",
            value: "N",
          },
          {
            description: "Backorder",
            value: "B",
          },
        ],
        editPartIndex: -1,
        editPartNumberIndex: -1,
  
      };
    },
    computed: {
      ...mapState(["customerInquiry"]),
      ...mapGetters({
        getClient: "session/getClient",
        getUsers: "users/getUsers",
        getTerms: "customerInquiry/getTerms",
        getPricingCodes: "customerInquiry/getPricingCodes",
        getCompanyCodes: "customerInquiry/getCompanyCodes",
        getCurrentUserData: "session/getUser",
      }),
      header() {
        return this.isEditing ? `Quote #${this.id}` : "New Quote";
      },
  
      disabledInput() {
      return this.prospectName !== "";
    },
      companyCodes() {
        return this.getCompanyCodes;
      },
      formatPartInfo() {
        const reformattedPartInfo: Array<any> = [];
  
        if(this.soQuote.li_items != undefined){
            this.soQuote.li_items?.forEach((LiItemObj: LiItems) => {
          if(LiItemObj.qty_items != null && LiItemObj.qty_items.length > 0) {
            LiItemObj.qty_items?.forEach((qty_item_obj: QtyItems) => {
              let newQtyObject: QtyItems;
              let priceTotal;
              if(isNaN(parseFloat(LiItemObj.disc_pct as string)) == true || isNaN(parseFloat(qty_item_obj.price as string))) {
                priceTotal = parseFloat(qty_item_obj.price as string) * parseFloat(qty_item_obj.qty as string);
                priceTotal = priceTotal
                  .toFixed(2)
                  .toString();
                if (isNaN(parseFloat(priceTotal as string))) {
                  priceTotal = 0
                  priceTotal.toString();
                }
              } else {
                const discountAmount = parseFloat(qty_item_obj.price as string) * (parseFloat(LiItemObj.disc_pct as string) / 100) * parseFloat(qty_item_obj.qty as string);
                priceTotal = parseFloat(qty_item_obj.price as string) * parseFloat(qty_item_obj.qty as string) - discountAmount;
                priceTotal = priceTotal
                  .toFixed(2)
                  .toString();
              }
              newQtyObject = {
                part: LiItemObj.part,
                qty: qty_item_obj.qty ? parseFloat(qty_item_obj.qty as string) : 0,
                wrap_desc: LiItemObj.wrap_desc,
                disc_pct: LiItemObj.disc_pct ? parseFloat(LiItemObj.disc_pct as string) : 0,
                price: qty_item_obj.price ? parseFloat(qty_item_obj.price as string) : 0,
                material_cost: qty_item_obj.material_cost,
                labor_cost: qty_item_obj.labor_cost,
                fixed_overhead: qty_item_obj.fixed_overhead,
                var_overhead: qty_item_obj.var_overhead,
                other_cost: qty_item_obj.other_cost,
                total_cost: qty_item_obj.total_cost,
                markup_pct: qty_item_obj.markup_pct,
                extended_price: priceTotal ? parseFloat(priceTotal as string).toFixed(2) : parseFloat("0").toFixed(2),
              };
              reformattedPartInfo.push(newQtyObject);
            });
          } else {
              let newLiItemObj = {
                  li: LiItemObj.li,
                  part: LiItemObj.part,
                  disc_pct: LiItemObj.disc_pct,
                  wrap_desc: LiItemObj.wrap_desc,
                  qty_items: LiItemObj.qty_items,
                  item_cost: LiItemObj.item_cost,
                  desc_items: LiItemObj.desc_items,
                  li_rep_items: LiItemObj.li_rep_items,
                  cost: LiItemObj.cost
              }
            reformattedPartInfo.push(newLiItemObj);
          }
        });
        } 
        return reformattedPartInfo;
      }
    },
    methods: {
      ...mapActions({
        fetchTerms: "customerInquiry/getTerms",
        fetchControls: "customerInquiry/getControls",
        removeQuotePDF: "soQuotes/removeQuotePDF",
        addNotification: "notification/add",
        pushSalesId: "customerInquiry/pushSalesId",
        fetchOrdersRecords: "sales/fetchOrdersRecords",

      }),
      removePart(index: number) {
        this.soQuote.li_items.splice(index, 1);
      },
      setEditpartIndex(index: number) {
        this.editPartIndex = index;
      },
      savePartDescription(index: number) {
        this.soQuote.li_items[index].wrap_desc = this.formatPartInfo[index].wrap_desc;
        this.editPartIndex = -1;
      },
      resetEditpartIndex(index: number) {
        this.formatPartInfo[index].wrap_desc = this.soQuote.li_items[index].wrap_desc;
        this.editPartIndex = -1;
      },
      setEditPartNumberIndex(index: number) {
        this.editPartNumberIndex = index;
      },
      savePartNumber(index: number) {
        const priceService = new PartsService();
          priceService.getPriceByPartId(
            this.formatPartInfo[index].part,
            this.getClient,
            this.soQuote.li_items[this.editPartNumberIndex].qty_items[0].qty.toString(),
            this.soQuote.code,
            this.soQuote.customer,
            this.soQuote.date,
          ).then(response => {
            const res = (response as unknown as ResponseObject);
            this.soQuote.li_items[index].qty_items[0].price = res.price || 0;
            this.soQuote.li_items[index].wrap_desc = res.wrap_desc || '';
          });
        this.soQuote.li_items[index].part = this.formatPartInfo[index].part;
        this.editPartNumberIndex = -1;
      },
      resetEditPartNumberIndex(index: number) {
        this.formatPartInfo[index].part = this.soQuote.li_items[index].part;
        this.editPartNumberIndex = -1;
      },
      formatCurrency(amount: string){
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        return formatter.format(parseFloat(amount))
      },
      onInput(context: any, event: any) {
        let data = context.data
        let field = context.field
        let index = context.index
        let newValue = event.value
  
        let qty_items = {
          part: data.part,
          qty: data.qty,
          price: data.price,
          material_cost: data.material_cost,
          labor_cost: data.labor_cost,
          fixed_overhead: data.fixed_overhead,
          var_overhead: data.var_overhead,
          other_cost: data.other_cost,
          total_cost: data.total_cost,
          markup_pct: data.markup_pct,
          wrap_desc: data.wrap_desc,
          disc_pct: data.disc_pct,
          extended_price: data.extended_price
          } as any;
  
        let li_item = {
            li: index + 1,
            part: data.part,
            qty_items: [],
            item_cost: data.item_cost,
            desc_items: data.desc_items,
            li_rep_items: data.li_rep_items,
            cost: data.cost,
            disc_pct: data.disc_pct,
            wrap_desc: data.wrap_desc,        
        }
        if(field in qty_items) {
          qty_items[field as keyof typeof qty_items] = newValue
        }
        if(field in li_item) {
          li_item[field as keyof typeof li_item] = newValue
        }
  
        this.soQuote.li_items.splice(index, 1, li_item);
        this.soQuote.li_items[index].qty_items.splice(-1, 1, qty_items); 
      },
  
      async createOrder(){
       const isCompanyFieldPopulated = this.v$.co_code.$validate();
        const isCustFieldPopulated = this.v$.soQuote.$validate();
  
        if ((await isCompanyFieldPopulated) && (await isCustFieldPopulated)) {
          this.isLoadingOrder = true;
  
          this.mapQuoteToOrder();
          this.isOppLoadedInDialog = false;
  
          this.salesService.postOrder(this.salesOrder)
            .then((response) => {
              this.isLoadingOrder = false;
              const saleId = (response as any).recordId;
              this.pushSalesId({
                index: 0,
                id: saleId,
              });
              this.fetchOrdersRecords({
                ids:saleId,
                correls: "cust_name rep_name"
              })
              this.addNotification({
                message: `Successfully Created Sale Id ${saleId}.`,
                type: "success",
              });
            })
            .catch((error) => {
              this.isLoadingOrder = false;
            });
        }
      },
      formatQuoteToOrderPart(object: soquote) {
        const reformattedPartInfo: Array<LisItems> = [];
  
        object.li_items?.forEach((qtyObject: LiItems) => {
          if (qtyObject.qty_items != null && qtyObject.qty_items.length > 0) {
            qtyObject.qty_items?.forEach((partObject: QtyItems) => {
              let transformedlistItemObject: LisItems;
              transformedlistItemObject = {
                lis: (reformattedPartInfo.length + 1).toString(),
                li_parts: qtyObject.part,
                li_order_qtys: Number(partObject.qty).toFixed().toString() || "",
                li_prices: partObject.price,
                li_wrap_desc: partObject.wrap_desc,
                li_sched_dates_items: [
                  {
                    li_sched_dates: Utils.formatDate(new Date()),
                    li_sched_qtys:
                      Number(partObject.qty).toFixed().toString() || "",
                  },
                ],
              } as unknown as LisItems;
              reformattedPartInfo.push(transformedlistItemObject);
            });
          }
        });
        this.salesOrder.lis_items = reformattedPartInfo;
      },
      mapQuoteToOrder(){
        this.salesOrder.contact_email = this.soQuote.email;
        this.salesOrder.phone = this.soQuote.phone;
        this.salesOrder.co_code = this.soQuote.co_code;
        this.salesOrder.terms_code = this.soQuote.terms;
        this.salesOrder.cust_code = this.soQuote.code;
        (this.salesOrder.lis_items as unknown) = this.soQuote.li_items || [];
        this.salesOrder.date = Utils.formatDate(new Date());
        this.salesOrder.sold_to = this.soQuote.customer;
        this.salesOrder.contact = this.soQuote.contact_id;
        this.salesOrder.status = this.soQuote.status;
        // this.salesOrder.rep_items = this.soQuote.rep_items;
  
        this.formatQuoteToOrderPart(this.soQuote);
      },
  
      terms() {
        this.customerInquiry.terms.map((element: any) => {
          return element;
        });
      },
      openAddExistingQuoteDialog() {
        this.showAddExistingQuoteDialog = true;
      },
      openAddOpportunityDialog(){
        this.showAddOpportunityDialog = true;
      },
      resetFields(fieldCode: string) {
        switch (fieldCode) {
          case 'QUOTES':
            this.isLoadQuoteFormSubmitted = false;
            this.isLoadingQuote = false;
            this.existingSOQuote = "";
            break;
          case 'OPPS':
            this.existingOpportunity = "";
            this.isLoadingOpportunity = false;
            this.isLoadOppFormSubmitted = false;
            break;
        }
        this.statusMessage = "";
      },
      async populateDialog() {
        this.isLoadQuoteFormSubmitted = true;
  
        const isValidQuoteInput = await this.v$.existingSOQuote.$validate();
  
        if (isValidQuoteInput) {
          this.isLoadingSOQuote = true;
          this.isLoadingQuote = true;
          this.isOrderEmpty = false;
          this.statusMessage = "";
  
          this.soQuoteService
            .getSOQuotes(this.existingSOQuote)
            .then((response) => {
              this.isOppLoadedInDialog = false;
              if ((response as any).soquote_items?.length === 1) {
                const SOQuoteRecord = (response as any)
                  .soquote_items[0] as soquote;
                SOQuoteRecord.id = "";
                this.soQuote = SOQuoteRecord;
                this.setCust({ cust_id: this.soQuote.customer } as Customer);
                if (this.soQuote.customer != "") {
                  if(this.soQuote.name) {
                    this.newSoQuoteCust = this.soQuote.name;
                  } else {
                    this.custService
                      .getCustomer(this.soQuote.customer, this.getClient)
                      .then((response) => {
                        const cust = response as Customer;
                        this.newSoQuoteCust = cust.name as string;
                        this.isLoadingSOQuote = false;
                        this.showAddExistingQuoteDialog = false;
                        this.existingSOQuote = "";
                        this.statusMessage = "";
                        this.isLoadQuoteFormSubmitted = false;
                      });
                    }
                  this.showAddExistingQuoteDialog = false;
                  this.existingSOQuote = "";
                }
                this.initProspect();
              } else {
                this.isLoadingSOQuote = false;
                this.isLoadingQuote = false;
                this.isOrderEmpty = true;
                this.statusMessage = "No record found";
              }
            })
            .catch(() => {
              this.isLoadQuoteFormSubmitted = false;
              this.statusMessage = "Failed to load quote";
            });
        }
      },
      async populateDialogWithOpp(){
        
        const isValidOppInput = await this.v$.existingOpportunity.$validate();
  
        if (isValidOppInput) {
          this.isLoadOppFormSubmitted = false;
          this.isLoadingOpportunity = true;
          this.isOpportunityEmpty = false;
          this.statusMessage = "";
  
          this.saleOppService.getSaleOpps(this.existingOpportunity, 1, 1, "", "", "", "", "", "", "", "cust_name")
            .then((response) => {
              if ((response as any).saleopp_items?.length === 1) {
                this.saleOpp = (response as any).saleopp_items[0];
                const opportunityRecord = (response as any).saleopp_items[0] as SaleOpp;
                this.isLoadingOpportunity = false;
                this.isOppLoadedInDialog = true;
    
                // MAP QUOTE TO SALE OPP
                this.soQuote.id = "";
                this.soQuote.date = Utils.formatDate(new Date());
                this.soQuote.prospect = opportunityRecord.prospect || "";
                this.soQuote.customer = opportunityRecord.cust;
                this.soQuote.email = "";
                this.soQuote.phone = "";
                this.soQuote.quoted_by = opportunityRecord.assigned_to || "";
                this.selectedQuoter = opportunityRecord.assigned_to || "";
    
                if (this.soQuote.customer != "") {
                  this.setCust({ cust_id: this.soQuote.customer } as Customer, opportunityRecord.contact);

                  this.custService
                    .getCustomer(this.soQuote.customer, this.getClient)
                    .then((response) => {
                      const cust = (response as Customer);
                      this.newSoQuoteCust = cust.name as string;
                      this.soQuote.address_items = cust.address_items as Array<AddressItem>;
                      if(opportunityRecord.est_close) {
                        let oppDate = new Date(opportunityRecord.est_close)
                        this.soQuote.date = Utils.formatDate(oppDate);
                        let valid_thru = new Date(oppDate.getTime() + (30 * 24 * 60 * 60 * 1000));
                        this.formatDate(valid_thru, 'VALID_THRU_DATE');
                      }
                    });
                  if (this.soQuote.contact_id != "") {
                    this.soQuoteContact([{ contact_id: opportunityRecord.contact, contact_type: 'SO' } as basecontact]);
                  }
                  this.showAddOpportunityDialog = false;
                  this.existingOpportunity = "";
                  this.statusMessage = "";
                  this.isLoadOppFormSubmitted = false;
                }
                this.initProspect();
              } else {
                this.isLoadingOpportunity = false;
                this.isOpportunityEmpty = true;
                this.statusMessage = "No record found";
              }
  
            }).catch(() => {
              this.isLoadingOpportunity = false;
              this.isLoadOppFormSubmitted = false;
              this.statusMessage = "Failed to load opportunity";
            });
        }
      },
      initControls() {
        const CONTROLS = [
          {
            control: "price",
            id: "PRICE",
            filename: "CONTROL",
            procedure: "price.CONTROL",
            getter: this.getPricingCodes,
          },
          {
            id: "COMPANY",
            procedure: "CO.CONTROL",
            filename: "CONTROL",
            getter: this.getCompanyCodes,
          },
        ];
  
        CONTROLS.forEach((element: any) => {
          if (element.getter == null || element.getter?.length === 0) {
            this.fetchControls(element);
          }
        });
      },
      initCust() {
        const cust = this.customer as Customer;
        this.soQuote.customer = cust.cust_id as string;
        this.soQuote.name = cust.name as string;
        this.soQuote.contact_id = this.customer ? this.customer?.contact_id_items[0]?.contact_id : "";
        this.soQuote.terms = cust.terms as string;
        this.soQuote.resale_no = cust.resale as string;
        this.soQuote.code = cust.code as string;
        this.newSoQuoteCust = cust.name as string;
      },
      setCust(element: Customer, contact_id?: string) {
        const service = new CustomerService();
        service.getCustomer(element.cust_id, this.getClient).then((response) => {
          const cust = response as Customer;
          this.soQuote.customer = cust.cust_id as string;
          if (cust.terms != null || cust.terms != undefined) {
            this.soQuote.terms = cust.terms as string;
          }
          if (cust.name != null || cust.name != undefined) {
            this.soQuote.name = cust.name;
          }
          if (cust.code != null || cust.code != undefined) {
            this.soQuote.code = cust.code;
          }
          if (cust.contact_id_items && cust.contact_id_items.length) {
            this.soQuote.contact_id = contact_id ? contact_id : cust.contact_id_items[0].contact_id as string;
          }
        });
      },
      soQuoteContact(contacts: Array<basecontact>) {
        if (contacts) {
          contacts.forEach((contact: basecontact) => {
            if (contact.contact_type == "SO") {
              const service = new ContactService(
                process.env.VUE_APP_ABSTRACTION_API
              );
              service
                .getContacts(contact.contact_id, this.getClient)
                .then((response) => {
                  const contact = (response as Array<Contact>)[0];
                  if (
                    contact.telephone_items != null ||
                    contact.telephone_items != undefined
                  ) {
                    this.soQuote.phone = contact.telephone_items[0].telephone;
                  }
                  if (
                    contact.email_address_items != null ||
                    contact.email_address_items != undefined
                  ) {
                    this.soQuote.email =
                      contact.email_address_items[0].email_address;
                  }
                  if (contact.fax != null || contact.fax != undefined) {
                    this.soQuote.fax = contact.fax;
                  }
                  this.soQuote.contact_id = contact.contact_id;
                  this.newSoQuoteContact =
                    contact.first_name + " " + contact.last_name;
                  this.soQuote.contact = `${contact.first_name} ${contact.last_name ? contact.last_name : ''}`;
                });
            }
          });
        }
      },
      findContact() {
        return "";
      },
      setPart(event: Part) {
        if (this.editPartNumberIndex !== -1) {
          this.soQuote.li_items[this.editPartNumberIndex].part = event.part_no;
        } else {
          this.part.number = event.part_no;
        }
      },
      setProspect(event: Prospect) {
        this.prospectName = event.name;
        this.soQuote.prospect = event.id;
        this.soQuote.name = event.name
      },
      initProspect() {
        if (this.soQuote.prospect) {
          this.prospectService
            .getProspect(this.soQuote.prospect)
            .then((response) => {
              this.prospectName = (response as Prospect).name as string;
            });
        }
      },
      formatDate(date: Date, field: string) {
        const formattedString = Utils.formatDate(date);
        switch (field) {
          case "PART":
            this.part.date = formattedString;
            break;
          case "STATUS_DATE":
            this.soQuote.status_date = formattedString;
            break;
          case "VALID_THRU_DATE":
            this.soQuote.valid_thru = formattedString;
            break;
          case "QUOTED_DATE":
            this.soQuote.date = formattedString;
            break;
        }
        return formattedString;
      },
      addPart() {
        this.initSoQuote
        this.partLoading = true;
        const priceService = new PartsService();
        const qty = this.part.quantity.toString();
          priceService
          .getPriceByPartId(
            this.part.number,
            this.getClient,
            qty,
            this.soQuote.code,
            this.soQuote.customer,
            this.part.date
          )
          .then((response) => {
            const qty = this.part.quantity.toString();
            const line_item = {
              li: ((this.soQuote.li_items.length || 0) + 1).toString(),
              part: this.part.number,
              disc_pct: this.part.disc_pct,
              wrap_desc: (response as unknown as ResponseObject).wrap_desc,
              qty_items: [
                {
                  qty: qty,
                  price: (response as unknown as ResponseObject).price,
                },
              ],
            } as LiItems;
            this.soQuote.li_items.push(line_item);
            this.clearPart();
            this.partLoading = false;
            this.showPartModal = false;
          });
      },
      clearPart() {
        this.part.quantity = 0;
        this.part.number = "";
        this.part.date = "";
        this.part.wrap_desc = "";
        this.part.disc_pct = "";
      },
      async submit() {
        this.isFormSubmitted = true;
  
        const isCompanyFieldPopulated = this.v$.co_code.$validate();
        const isCustFieldPopulated = this.v$.soQuote.$validate();
  
        if ((await isCompanyFieldPopulated) && (await isCustFieldPopulated)) {
          this.submitLoading = true;
          this.soQuote.quoted_by = this.selectedQuoter;
  
            if(this.isEditing){
              this.soQuoteService
              .putSOQuote({
                quoteId: this.soQuote.id, 
                newQuote: this.soQuote, 
                oldQuote: this.oldQuote
                })
              .then((response) => {
                  this.submitLoading = false;
                  this.$emit("onSave", this.soQuote);
                  this.removeQuotePDF(this.soQuote.id);
                  this.isFormSubmitted = false
                  this.hidden = false;
                  let soQuoteId = (response as any).recordId;
                  this.addNotification({
                      message: `Successfully updated SO Quote Id ${soQuoteId}.`,
                      type: "success",
                  });
              }).catch((error) => {
                    this.submitLoading = false;
                    this.isFormSubmitted = false
                    this.hidden = true;
                  });
            } else {
                this.soQuoteService
                .postSOQuote(this.soQuote)
                .then((response) => {
                  this.submitLoading = false;
                  this.soQuote.id = (response as any).recordId;
                  this.$emit("onSave", this.soQuote);
                  this.isFormSubmitted = false;
                  this.hidden = false;
                  let soQuoteId = (response as any).recordId;
                  this.clearPart();
                  this.addNotification({
                    message: `Successfully Created SO Quote Id ${soQuoteId}.`,
                    type: "success",
                  });
                  if (this.isOppLoadedInDialog) {
                const saleOppCopy = JSON.parse(JSON.stringify(this.saleOpp));
                saleOppCopy.quote_items == null ? saleOppCopy.quote_items = [{ quote: soQuoteId }] : saleOppCopy.quote_items.push({ quote: soQuoteId });
                
                this.saleOppService.updateSaleOpp(saleOppCopy, this.saleOpp).then(() => {
                  this.isOppLoadedInDialog = false;
                  this.addNotification({
                    message: `Successfully updated opportunity ${ this.saleOpp.id }.`,
                    type: "success",
                  });
                }).catch((error) => {
                  this.hidden = true
                });
              }
                })
                .catch((error) => {
                  this.submitLoading = false;
                  this.isFormSubmitted = false;
                  this.hidden = true
                });
            }
  
        } else {
          return (this.$refs.newSoQuoteCust as any).$el.focus();
        }
      },
      initSoQuote() {
        this.soQuote = {
          id: "",
          date: "",
          prospect: "",
          customer: "",
          name: "",
          phone: "",
          contact: "",
          status: "",
          quoted_by: this.selectedQuoter,
          email: "",
          fax: "",
          valid_thru: "",
          status_date: "",
          exchange_rate: "",
          cost_method: "",
          est_close: "",
          code: "",
          terms: "",
          li_items: [] as Array<LiItems>,
          address_items: [] as Array<AddressItem>,
          rev: "",
          co_code: "",
          resale_no: "",
          rep_items: [] as Array<RepItem>,
          change_date_items: [] as Array<ChangeDateItems>,
          notes: "",
          stamp_user: "",
          stamp_date: "",
          ext: "",
          reason_code: "",
          contact_id: "",
          currency_code: "",
        } as soquote;
        (this.newSoQuoteCust = ""),
          (this.newSoQuoteContact = ""),
          (this.prospectName = "");
      },
      async validatePartReq() {
        this.isFormSubmitted = true;
        const isCustFieldPopulated = await this.v$.newSoQuoteCust.$validate();
        if (isCustFieldPopulated) {
          this.showPartModal = true;
        }
      },
      focusSOQuoteSearch() {
        (this.$refs.soquoteInputText as any).$el.focus();
      },
      focusOpportunitySearch(){
        (this.$refs.opportunityInputText as any).$el.focus();
      },
      handleContactSelected(contact:any) {
        this.soQuote.contact = `${contact.first_name} ${contact.last_name ? contact.last_name : ''}`
        this.soQuote.contact_id = contact.contact_id;
      },
      updateEstClose(data: any) {
        this.soQuote.est_close = data.toString();
      }
    },
    updated() {
      this.isFormSubmitted = false;
       if (this.newQuote)
      {
        let curDate = new Date();
        let valid_thru = new Date(curDate.getTime() + (30 * 24 * 60 * 60 * 1000));
        this.soQuote.date = this.formatDate(curDate, "QUOTED_DATE")
        this.soQuote.valid_thru = this.formatDate(valid_thru, "VALID_THRU_DATE")
        this.soQuote.co_code = this.getCompanyCodes[0].code;
      } else {
        this.soQuote.date = this.formatDate(new Date(this.soQuote.date), "QUOTED_DATE")
      }
       
  
      if (this.customer) {
        this.initCust();
      }
      
  
      if (this.quote != null) {
        if (this.soQuote.customer != "") {
          if(this.soQuote.name) {
            this.newSoQuoteCust = this.soQuote.name;
          } else {
            this.custService
              .getCustomer(this.soQuote.customer, this.getClient)
              .then((response) => {
                const cust = response as Customer;
                this.newSoQuoteCust = cust.name as string;
              });
          }
        }
        this.initProspect();
        this.selectedQuoter = this.soQuote.quoted_by;
        this.showClear = false;
      }
    },
    watch: {
      show(newShow, oldShow) {
        if (!newShow) {
          this.initSoQuote();
        }
        this.hidden = newShow;
      },
      quote(newQuote, oldQuote) {
        this.soQuote = JSON.parse(JSON.stringify(this.quote as soquote));
      },
      formatPartInfo: {
        deep: true,
        handler: function (newValue) {
          let total = 0
          newValue.forEach((part: any) => {
            total += parseFloat(part.extended_price)
          });
          this.totalPrice = total.toFixed(2)
        }
      }
    },
  });
  