
import { defineComponent } from 'vue';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';

export default defineComponent({
  name: 'MultiselectWithButton',
  components: {
    MultiSelect,
    Button,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    optionValue: {
      type: String,
      default: '',
    },
    optionLabel: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      required: true,
    },
    btnLabel: {
      type: String,
      default: 'Apply',
    },
    filter: {
      type: Boolean,
      default: false,
    },
    filterMatchMode: {
      type: String,
      default: 'contains',
    },
    style: {
      type: Object,
      default: {} as unknown,
    },
    dataKey: {
      type: String,
      default: '',
    },
    maxSelectedLabels: {
      type: Number,
      default: 0,
    },
    filterFields: {
      type: Array,
      default: undefined,
    }
  },
  emits: ['btnCallback', 'updateFilterValue', 'filter'],
  created(){
    this.innerValue = this.value;
  },
  data: () => ({
    innerValue: [] as unknown[],
  }),
  methods: {
    btnCallback() {
      this.$emit('btnCallback');
    },
    updateValue() {
      this.$emit('updateFilterValue', this.innerValue);
    }
  },
});
