<template>
  <div class="flex justify-content-center flex-wrap">
      <div class="flex align-items-center justify-content-center">
        <ProgressSpinner v-if="loading" data-testid="loading-spinner"/>
      </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
    name: 'LoadingSpinner',
    props: {
      loading: {
        type: Boolean,
        required: true
      },
    },
    components: {
        ProgressSpinner,
    }

})
</script>

<style>

</style>