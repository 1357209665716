import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-0 pb-1 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MultiSelect, {
      modelValue: _ctx.innerValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        _ctx.updateValue
      ],
      options: _ctx.options,
      optionValue: _ctx.optionValue,
      optionLabel: _ctx.optionLabel,
      placeholder: _ctx.placeHolder,
      filter: _ctx.filter,
      filterMatchMode: _ctx.filterMatchMode,
      style: _normalizeStyle(_ctx.style),
      dataKey: _ctx.dataKey,
      maxSelectedLabels: _ctx.maxSelectedLabels,
      filterFields: (_ctx.filterFields as any),
      onFilter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('filter', $event)))
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Button, {
            type: "button",
            label: _ctx.btnLabel,
            class: "w-12 h-2rem",
            onClick: _ctx.btnCallback
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "options", "optionValue", "optionLabel", "placeholder", "onUpdate:modelValue", "filter", "filterMatchMode", "style", "dataKey", "maxSelectedLabels", "filterFields"])
  ]))
}